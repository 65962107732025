import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import './ProductLabel.style';

/** @namespace Pwa/Component/ProductLabel/Component/ProductLabel */
export class ProductLabel extends PureComponent {
    static propTypes = {
        labels: PropTypes.array,
    };

    static defaultProps = {
        labels: [],
    };

    renderLabel(item) {
        const { text_color, text_background_color, text, url, code } = item;

        if (url) {
            return (
                <Link
                    to={url}
                    key={code}
                    block="ProductLabel"
                    elem="Text"
                    style={{ background: text_background_color, color: text_color }}
                >
                    {text}
                </Link>
            );
        }

        return (
            <div
                key={code}
                block="ProductLabel"
                elem="Text"
                style={{ background: text_background_color, color: text_color }}
            >
                {text}
            </div>
        );
    }

    renderImageLabel(item) {
        const { image, code, url } = item;

        if (url) {
            return (
                <Link to={url} key={code} block="ProductLabel" elem="Image" newTab>
                    <img src={image} alt={code} />
                </Link>
            );
        }

        return (
            <div key={code} block="ProductLabel" elem="Image">
                <img src={image} alt={code} />
            </div>
        );
    }

    render() {
        const { labels } = this.props;
        const [{ type, ...label } = {}] = labels || [];

        if (!type) {
            return null;
        }

        return (
            <div block="ProductLabel">{type === 'Image' ? this.renderImageLabel(label) : this.renderLabel(label)}</div>
        );
    }
}

export default ProductLabel;
