import { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    DEMO_NOTICE,
    FOOTER,
    HEADER,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
} from 'Component/Router/Router.config';
import UrlRewrites from 'Route/UrlRewrites';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex,
} from 'SourceComponent/Router/Router.component';
import {
    ADDRESS_BOOK,
    MY_DOWNLOADABLE,
    MY_GDPR,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION,
    RMA_ORDER_LIST,
    RMA_STATUS_LIST,
} from 'Type/Account.type';

import {
    ACCOUNT_FORGOT_PASSWORD,
    CART,
    CART_PREVIEW,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    CMS_PREVIEW,
    COMPARE,
    CONFIRM_ACCOUNT,
    CONFIRM_POPUP,
    CONTACT_PAGE,
    CREATE_ACCOUNT,
    GDPR_POPUP,
    HEADING_BANNER,
    HOME,
    LOGIN,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_GDPR,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_RMA_ORDER_LIST,
    MY_ACCOUNT_RMA_STATUS_LIST,
    MY_ACCOUNT_WISHLIST,
    NEWSLETTER_SUBSCRIPTION_PAGE,
    SEARCH,
    SEARCHING_PAGE,
    SHARED_WISHLIST,
    SOCIAL_PROOF_LIST,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    THANK_YOU_PAGE,
    URL_REWRITES,
} from './Router.config';

export {
    CartPage,
    CmsPage,
    Checkout,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    withStoreRegex,
};
export const SearchingPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "searching" */ 'Route/SearchingPage')
);
export const WishlistPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/WishlistPage')
);
export const BlueMediaPaymentStatus = lazy(() =>
    import(/* webpackMode: "lazy", webpackPrefetch: true */ 'Route/BlueMediaPaymentStatus')
);
export const ThankYouPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "thankyou" */ 'Route/ThankYouPage')
);
export const NewsletterSubscriptionPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "newsletter-subscription" */ 'Route/NewsletterSubscriptionPage')
);
export const GDPRPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "gdpr" */ 'Component/GDPRPopup'));
export const HeadingBanner = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "headingbanner" */ 'Component/HeadingBanner')
);
export const SocialProofList = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "socialprooflist" */ 'Component/SocialProofList')
);
export const ConfirmPopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "confirm-popup" */ 'Component/ConfirmPopup')
);

/** @namespace Pwa/Component/Router/Component/Router */
export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST,
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: DEMO_NOTICE,
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER,
        },
        {
            component: <NavigationTabs />,
            position: 25,
            name: NAVIGATION_TABS,
        },
        {
            component: <HeadingBanner />,
            position: 28,
            name: HEADING_BANNER,
        },
        {
            component: <Breadcrumbs isPluginBreadcrumb={false} />,
            position: 30,
            name: BREADCRUMBS,
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP,
        },
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={withStoreRegex('/')} exact render={(props) => <HomePage {...props} />} />,
            position: 10,
            name: HOME,
        },
        {
            component: <Route path={withStoreRegex('/search/:query/')} render={(props) => <SearchPage {...props} />} />,
            position: 25,
            name: SEARCH,
        },
        {
            component: <Route path={withStoreRegex('/page')} render={(props) => <CmsPage {...props} />} />,
            position: 40,
            name: CMS_PAGE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/preview/:identifier?')}
                    render={(props) => <CmsPage isPreview {...props} />}
                />
            ),
            position: 45,
            name: CMS_PREVIEW,
        },
        {
            component: <Route path={withStoreRegex('/cart')} exact render={(props) => <CartPage {...props} />} />,
            position: 50,
            name: CART,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/cart/load/guestCartId/:previewCartId')}
                    exact
                    render={(props) => <CartPage isPreview {...props} />}
                />
            ),
            position: 52,
            name: CART_PREVIEW,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/cart/share/:sharedCartId?')}
                    render={(props) => <CartPage isShared {...props} />}
                />
            ),
            position: 52,
            name: CART,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/cart/load/guestCartId/:cartId')}
                    exact
                    render={(props) => <CartPage isPreview {...props} />}
                />
            ),
            position: 52,
            name: CART_PREVIEW,
        },
        {
            component: (
                <Route
                    path={[withStoreRegex('/checkout'), withStoreRegex('/checkout/success')]}
                    render={(props) => <Checkout {...props} />}
                />
            ),
            position: 55,
            name: CHECKOUT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/createPassword/')}
                    render={(props) => <PasswordChangePage {...props} />}
                />
            ),
            position: 60,
            name: CHANGE_PASSWORD,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/create/')}
                    render={(props) => <CreateAccountPage {...props} />}
                />
            ),
            position: 61,
            name: CREATE_ACCOUNT,
        },
        {
            component: (
                <Route
                    path={[withStoreRegex('/customer/account/login/'), withStoreRegex('/customer/login/rma/')]}
                    render={(props) => <LoginAccountPage {...props} />}
                />
            ),
            position: 62,
            name: LOGIN,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/forgotpassword/')}
                    render={(props) => <ForgotPasswordPage {...props} />}
                />
            ),
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/confirm')}
                    render={(props) => <ConfirmAccountPage {...props} />}
                />
            ),
            position: 65,
            name: CONFIRM_ACCOUNT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/view/order_id/:orderId?')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_ORDERS} />}
                />
            ),
            position: 70,
            name: MY_ACCOUNT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/history')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_ORDERS} />}
                />
            ),
            position: 71,
            name: MY_ACCOUNT_ORDERS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/downloadable/customer/products')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_DOWNLOADABLE} />}
                />
            ),
            position: 72,
            name: MY_ACCOUNT_DOWNLOADABLE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/wishlist')}
                    exact
                    render={(props) => <WishlistPage {...props} selectedTab={MY_WISHLIST} />}
                />
            ),
            position: 73,
            name: MY_ACCOUNT_WISHLIST,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/address')}
                    render={(props) => <MyAccount {...props} selectedTab={ADDRESS_BOOK} />}
                />
            ),
            position: 74,
            name: MY_ACCOUNT_ADDRESS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/newsletter/manage')}
                    render={(props) => <MyAccount {...props} selectedTab={NEWSLETTER_SUBSCRIPTION} />}
                />
            ),
            position: 75,
            name: MY_ACCOUNT_NEWSLETTER,
        },
        {
            component: (
                <Route path={withStoreRegex('/customer/account/:tab?')} render={(props) => <MyAccount {...props} />} />
            ),
            position: 76,
            name: MY_ACCOUNT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/rma/orders/:orderId?')}
                    render={(props) => <MyAccount {...props} selectedTab={RMA_ORDER_LIST} />}
                />
            ),
            position: 78,
            name: MY_ACCOUNT_RMA_ORDER_LIST,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/rma/statuses/:requestId?')}
                    render={(props) => <MyAccount {...props} selectedTab={RMA_STATUS_LIST} />}
                />
            ),
            position: 77,
            name: MY_ACCOUNT_RMA_STATUS_LIST,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/gdpr')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_GDPR} />}
                />
            ),
            position: 78,
            name: MY_ACCOUNT_GDPR,
        },
        {
            component: <Route path={withStoreRegex('/menu')} render={(props) => <MenuPage {...props} />} />,
            position: 80,
            name: MENU,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/wishlist/shared/:code')}
                    render={(props) => <WishlistShared {...props} />}
                />
            ),
            position: 81,
            name: SHARED_WISHLIST,
        },
        {
            component: <Route path={withStoreRegex('/contact')} render={(props) => <ContactPage {...props} />} />,
            position: 82,
            name: CONTACT_PAGE,
        },
        {
            component: (
                <Route path={withStoreRegex('/compare')} render={(props) => <ProductComparePage {...props} />} />
            ),
            position: 83,
            name: COMPARE,
        },
        {
            component: <Route path={withStoreRegex('/styleguide')} render={(props) => <StyleGuidePage {...props} />} />,
            position: 84,
            name: STYLE_GUIDE,
        },
        {
            component: <Route path={withStoreRegex('/bluepayment/status')} exact component={BlueMediaPaymentStatus} />,
            position: 85,
        },
        {
            component: (
                <Route path={withStoreRegex('/thank-you-page')} render={(props) => <ThankYouPage {...props} />} />
            ),
            position: 86,
            name: THANK_YOU_PAGE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/searching/:query/')}
                    exact
                    render={(props) => <SearchingPage {...props} />}
                />
            ),
            position: 98,
            name: SEARCHING_PAGE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/newsletter')}
                    exact
                    render={(props) => <NewsletterSubscriptionPage {...props} />}
                />
            ),
            position: 99,
            name: NEWSLETTER_SUBSCRIPTION_PAGE,
        },
        {
            component: <Route render={(props) => <UrlRewrites {...props} />} />,
            position: 1000,
            name: URL_REWRITES,
        },
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER,
        },
        {
            component: <GDPRPopup />,
            position: 20,
            name: GDPR_POPUP,
        },
        {
            component: <SocialProofList />,
            position: 30,
            name: SOCIAL_PROOF_LIST,
        },
        {
            component: <ConfirmPopup />,
            position: 40,
            name: CONFIRM_POPUP,
        },
    ];
}

export default Router;
