/* eslint-disable array-callback-return */

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    CALCULATOR_UNIT,
    MINIMAL_VISIBLE_RATING_PERCENT_VAL,
    OMNIBUS_LAST_DAYS,
} from 'Component/ProductActions/ProductActions.config';
import ProductDiscountLabel from 'Component/ProductDiscountLabel';
import ProductLabel from 'Component/ProductLabel';
import ProductPrice from 'Component/ProductPrice';
import ProductStockAvailability from 'Component/ProductStockAvailability';
import { DEFAULT_VARIANT_LABEL } from 'Component/ProductVariants/ProductVariants.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { formatPrice } from 'Util/Price';

import { COLOR_SWATCH_PRODUCER_CODE, COLOR_VARIANT_CODE, NO_SELECTION } from './ProductCard.config';

import './ProductCard.override.style';

/** @namespace Pwa/Component/ProductCard/Component/ProductCard */
export class ProductCard extends SourceProductCard {
    static defaultProps = {
        ...SourceProductCard.defaultProps,
        extended: false,
        isWidget: false,
        hiddenActions: false,
        hiddenHoverSection: false,
        hiddenDeliveryTime: false,
        isArrangement: false,
    };

    state = {
        isColorVariantsVisible: false,
        regularPrice: null,
        finalPrice: null,
    };

    renderArrangementRegularPrice() {
        const {
            product: {
                price_range: {
                    maximum_price: {
                        regular_price: { value, currency },
                    },
                },
            } = {},
        } = this.props;

        if (!value) {
            return null;
        }

        return <>{formatPrice(value, currency)}</>;
    }

    renderArrangementFinalPrice() {
        const {
            product: {
                price_range: {
                    minimum_price: {
                        final_price: { value, currency },
                    },
                },
            } = {},
        } = this.props;

        if (!value) {
            return null;
        }

        return <>{formatPrice(value, currency)}</>;
    }

    renderArrangementDiscount() {
        const {
            product: {
                price_range: {
                    maximum_price: {
                        regular_price: { currency },
                        discount: { amount_off },
                    },
                },
            } = {},
        } = this.props;

        if (amount_off === 0) {
            return null;
        }

        return (
            <div block="ProductCard" elem="Discount">
                <ProductDiscountLabel
                    discountType="fixed"
                    fixedDiscount={formatPrice(amount_off, currency)}
                    displayWithMinus
                />
            </div>
        );
    }

    renderRelatedArrangementPrice() {
        const {
            product: {
                price_range: {
                    maximum_price: {
                        discount: { amount_off },
                    },
                },
            } = {},
            productPrice,
        } = this.props;

        const arrangementDiscountAvailable = amount_off > 0;

        if (!productPrice) {
            return null;
        }

        if (arrangementDiscountAvailable) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div block="ProductPrice" mix={{ block: 'ProductCard', elem: 'Price' }}>
                        <ins block="ProductPrice" elem="Price">
                            <span block="ProductPrice" elem="PriceValue">
                                {this.renderArrangementFinalPrice()}
                            </span>
                        </ins>
                        <del block="ProductPrice" elem="HighPrice">
                            {this.renderArrangementRegularPrice()}
                        </del>
                    </div>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <div block="ProductPrice" mix={{ block: 'ProductCard', elem: 'Price' }}>
                    <span block="ProductPrice" elem="Price">
                        <span block="ProductPrice" elem="PriceValue">
                            {this.renderArrangementFinalPrice()}
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    renderPrice() {
        const { getActiveProduct, product: { type_id: baseType } = {} } = this.props;

        const { price_range: priceRange, type_id: typeId } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        /* *** original source render below *** */
        const { productPrice, isWidget } = this.props;
        const product = getActiveProduct();

        const { type_id: type, price_tiers: priceTiers, product_unit } = product;
        const isProductWithCalculator = product_unit === CALCULATOR_UNIT;

        if (!productPrice) {
            return null;
        }

        return (
            <div block={this.className} elem="PriceWrapper">
                <ProductPrice
                    isSchemaRequired={isWidget}
                    price={productPrice}
                    priceType={type}
                    tierPrices={priceTiers}
                    isPreview={notConfigured}
                    isProductWithCalculator={isProductWithCalculator}
                    mix={{ block: this.className, elem: 'Price' }}
                />
            </div>
        );
    }

    renderAddToCartSecondary() {
        const { layout, showSelectOptionsNotification, inStock } = this.props;

        if (inStock && this.requiresConfiguration()) {
            return (
                <button block="Button AddToCart" mods={{ layout }} onClick={showSelectOptionsNotification}>
                    {__('Buy')}
                </button>
            );
        }

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <p>{__('Out of stock')}</p>
                </div>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    renderPicture(mix = {}, isWishlistArrangementItem) {
        const {
            product: { id, name, alternative_hover_image, recommandation_image, image },
            thumbnail,
            extended,
            isArrangement,
        } = this.props;
        const { url: alternativeHoverImageUrl, path: alternativeHoverImagePath } = alternative_hover_image || {};
        const { url: recommendationImageUrl, path: recommendationImagePath } = recommandation_image || {};

        const thumbnailImg = isArrangement ? image?.url : thumbnail;

        this.sharedComponent = (
            <Image
                imageRef={this.imageRef}
                src={extended && recommendationImagePath !== NO_SELECTION ? recommendationImageUrl : thumbnailImg}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
                extended={extended}
            />
        );
        if (
            !isWishlistArrangementItem &&
            !isArrangement &&
            (!extended || (extended && alternativeHoverImagePath !== NO_SELECTION))
        ) {
            this.hoverImage = (
                <Image
                    src={alternativeHoverImageUrl}
                    alt={name}
                    ratio="custom"
                    mix={{ block: 'ProductCard', elem: 'PictureHover', mix }}
                    isPlaceholder={!id}
                />
            );
        }

        return (
            <>
                {this.sharedComponent}
                {alternativeHoverImagePath !== NO_SELECTION && this.hoverImage}
                <img style={{ display: 'none' }} alt={name} src={thumbnailImg} />
            </>
        );
    }

    renderReviews() {
        const {
            layout,
            product: { rating_summary },
        } = this.props;

        const isRatingSummary = rating_summary > MINIMAL_VISIBLE_RATING_PERCENT_VAL;

        return (
            <div block="ProductCard" elem="Reviews" mods={{ layout }}>
                {isRatingSummary && this.renderRatingSummary()}
            </div>
        );
    }

    renderLowestPrice() {
        const {
            product: { lowest_price },
            hiddenOmnibus,
        } = this.props;

        if (!lowest_price || lowest_price === '' || hiddenOmnibus) {
            return null;
        }

        return (
            <div block="ProductCard" elem="LowestPrice">
                {__('Lowest price in the last %s days', OMNIBUS_LAST_DAYS)}: <span>{lowest_price}</span>
            </div>
        );
    }

    renderDiscount() {
        const {
            product: {
                discount_display,
                price_range: { maximum_price: { discount: { amount_off, percent_off = 0 } = {} } = {} } = {},
            },
            percentageDiscountTreshold,
        } = this.props;

        if (!percent_off || !amount_off || !discount_display || percent_off < percentageDiscountTreshold) {
            return null;
        }

        const fixedPrice = formatPrice(amount_off, this.getPriceCurrency());

        return (
            <div block="ProductCard" elem="Discount">
                <ProductDiscountLabel
                    discountType={discount_display}
                    fixedDiscount={fixedPrice}
                    percentageDiscount={percent_off}
                    displayWithMinus
                />
            </div>
        );
    }

    renderVisibleOnHover() {
        const { device, hiddenHoverSection } = this.props;
        const { isColorVariantsVisible } = this.state;
        if (device.isMobile || hiddenHoverSection) {
            return null;
        }

        return (
            <div block="ProductCard" elem="VisibleOnHover" mods={{ withVariants: isColorVariantsVisible }}>
                {this.renderConfigurableOptions()}
                {this.renderConfigurableAttributes()}
                <div block="ProductCard" elem="Footer">
                    {this.renderAddToCart()}
                </div>
            </div>
        );
    }

    renderListingName(listing_name) {
        return (
            <p block="ProductCard" elem="Name">
                <TextPlaceholder content={listing_name} length="medium" />
            </p>
        );
    }

    renderDeliveryTime() {
        const {
            hiddenDeliveryTime,
            product: { stock_availability, deliverer_available },
        } = this.props;

        if (hiddenDeliveryTime || !stock_availability?.qty) {
            return null;
        }

        return (
            <div block="ProductCard" elem="DeliveryTime">
                <ProductStockAvailability
                    stock_availability={stock_availability}
                    deliverer_available={deliverer_available}
                />
            </div>
        );
    }

    renderLabel() {
        const {
            product: { labels },
        } = this.props;

        return <ProductLabel labels={labels} />;
    }

    renderShortDescription(html) {
        return (
            <div block="ProductCard" elem="ShortDescription">
                <Html content={html} />
            </div>
        );
    }

    getActiveVariants(group, dataContainer, productID) {
        // eslint-disable-next-line
        group?.map(({ variants }) => {
            // eslint-disable-next-line
            Object.entries(variants)?.map((variant) => {
                if (variant[1].product.id === productID) {
                    // eslint-disable-next-line
                    Object.entries(variant[1].attributes)?.map((elem) => {
                        dataContainer[elem[1].attribute_code] = elem[1].label;
                    });
                }
            });
        });
    }

    renderVariantImage(src, color) {
        if (src) {
            return <img src={src} alt={color} />;
        }

        return null;
    }

    getUniqueMainVariants(arr, key) {
        if (key === DEFAULT_VARIANT_LABEL) {
            return Array.from(new Map(arr.map((item) => [item.attributes[0][key], item])).values());
        }
        const uniqueVariants = [];
        arr.map((item) => {
            if (item.attributes[1].label === key) {
                uniqueVariants.push(item);
            }
        });

        return uniqueVariants;
    }

    renderConfigurableAttributes() {
        const {
            product: { group, id },
        } = this.props;

        if (!group?.length) {
            return null;
        }

        const { variants } = group[0];
        const CARD_VARIANT_CODE = 'glebokosc_mm';
        const variant = variants[0]?.attributes.find((attribute) => attribute.attribute_code === CARD_VARIANT_CODE);

        if (!variant) {
            return null;
        }

        const activeVariants = {};
        this.getActiveVariants(group, activeVariants, id);

        return (
            <div block="ProductCard" elem="ConfigurableAttributes">
                <div block="ProductVariants" elem="Options">
                    {variants.map((variant) =>
                        variant.attributes.map((element) => {
                            const code = element.attribute_code;
                            const value = element.label;

                            if (activeVariants[code] === value && code !== CARD_VARIANT_CODE) {
                                const visible_variant_label = variant.attributes.filter(
                                    (card_variant) => card_variant.attribute_code === CARD_VARIANT_CODE
                                );

                                return (
                                    <Link
                                        to={variant.product.url}
                                        title={visible_variant_label[0].label}
                                        block="ProductCard"
                                        elem="ConfigurableAttribute"
                                    >
                                        {visible_variant_label[0].label}
                                    </Link>
                                );
                            }

                            return null;
                        })
                    )}
                </div>
            </div>
        );
    }

    renderVariants() {
        const {
            isWidget,
            hideVariants,
            product: { group, id },
        } = this.props;

        if (isWidget || hideVariants || !group?.length) {
            return null;
        }
        const activeVariants = {};
        this.getActiveVariants(group, activeVariants, id);
        const { variants } = group[0];
        const variant = variants[0]?.attributes.find(
            (attribute) =>
                attribute.attribute_code === COLOR_SWATCH_PRODUCER_CODE ||
                attribute.attribute_code === COLOR_VARIANT_CODE
        );
        const variantLabel =
            Object.keys(activeVariants).length > 1
                ? activeVariants[Object.keys(activeVariants)[1]]
                : DEFAULT_VARIANT_LABEL;

        const filteredVariants = this.getUniqueMainVariants(variants, variantLabel);
        const productVariants = filteredVariants.map((variant) =>
            variant.attributes.map((element) => {
                const code = element.attribute_code;
                const isSameAsProductID = variant.product.id === id;
                const isSameProductColor = element.label === activeVariants.color;

                if (
                    (code === COLOR_SWATCH_PRODUCER_CODE || code === COLOR_VARIANT_CODE) &&
                    !isSameAsProductID &&
                    !isSameProductColor
                ) {
                    return element;
                }

                return null;
            })
        );

        if (!variant || !(productVariants?.length > 1)) {
            return null;
        }

        this.setState({ isColorVariantsVisible: true });

        return (
            <div block="ProductCard" elem="Variants">
                {filteredVariants.map((variant) =>
                    variant.attributes.map((element) => {
                        const code = element.attribute_code;
                        const value = element.label;
                        const variant_image_url = variant.product.thumbnail;
                        const isSameAsProductID = variant.product.id === id;
                        const isSameProductColor = element.label === activeVariants.color;

                        if (
                            (code === COLOR_SWATCH_PRODUCER_CODE || code === COLOR_VARIANT_CODE) &&
                            !isSameAsProductID &&
                            !isSameProductColor
                        ) {
                            return (
                                <Link
                                    to={new URL(variant.product.url).pathname}
                                    title={value}
                                    block="ProductCard"
                                    elem="Variant"
                                >
                                    {this.renderVariantImage(variant_image_url, value)}
                                </Link>
                            );
                        }

                        return null;
                    })
                )}
            </div>
        );
    }

    renderProductActions() {
        const { hiddenActions, device } = this.props;
        if (hiddenActions || device.isMobile) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ProductActions">
                {this.renderProductCompareButton()}
                {this.renderProductCardWishlistButton()}
            </div>
        );
    }

    renderProductName() {
        const {
            product: { listing_name },
        } = this.props;

        return (
            <div block="ProductCard" elem="ProductNameContainer">
                {listing_name ? this.renderListingName(listing_name) : this.renderName(false)}
            </div>
        );
    }

    renderCardContent() {
        const {
            renderContent,
            extended,
            isArrangement,
            device: { isMobile, isTablet },
            product: { short_description = '' } = {},
        } = this.props;
        const isDesktop = !isMobile && !isTablet;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <div block="ProductCard" elem="ContentWrapper">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                        {isArrangement ? this.renderArrangementDiscount() : this.renderDiscount()}
                        {this.renderProductActions()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content">
                    {short_description?.html && extended && this.renderShortDescription(short_description.html)}
                    {isDesktop && extended && this.renderDiscount()}
                    {isArrangement ? this.renderRelatedArrangementPrice() : this.renderPrice()}
                    {this.renderLowestPrice()}
                    {this.renderProductName()}
                    {isDesktop && extended && this.renderAddToCartSecondary()}
                    {this.renderReviews()}
                    {!isArrangement && this.renderDeliveryTime()}
                </div>
                {this.renderVisibleOnHover()}
            </div>
        );
    }

    getPriceCurrency() {
        const {
            productPrice: {
                price: {
                    finalPrice: { currency },
                },
            },
        } = this.props;

        return currency;
    }

    builtProductsPrice(products, priceType) {
        return products
            .map((product) => product.price_range.minimum_price[priceType].value)
            .reduce((prev, curr) => prev + curr, 0);
    }

    renderRegularPrice() {
        const { product: { related_built_products } = {} } = this.props;

        if (!related_built_products) {
            return null;
        }

        const regularPrice = this.builtProductsPrice(related_built_products, 'regular_price');

        this.setState({ regularPrice });

        return (
            <>
                {/* <div>{__('Catalog price')}:</div>
                <div block="ProductActions" elem="PriceWrapper" mods={{ type: 'regularPrice' }}>
                    {formatPrice(regularPrice, this.getPriceCurrency())}
                </div> */}
                <span block="ProductPrice" elem="PriceValue">
                    {formatPrice(regularPrice, this.getPriceCurrency())}
                </span>
            </>
        );
    }

    renderSpecialPrice() {
        const { product: { related_built_products } = {} } = this.props;

        if (!related_built_products) {
            return null;
        }

        const specialPrice = this.builtProductsPrice(related_built_products, 'final_price');

        return (
            <>
                <div>{__('Domneo price')}:</div>
                <div block="ProductActions" elem="PriceWrapper" mods={{ type: 'domneoPrice' }}>
                    <span>{formatPrice(specialPrice, this.getPriceCurrency())}</span>
                </div>
            </>
        );
    }

    renderFinalPrice() {
        const {
            product: { related_built_products, attributes },
        } = this.props;

        if (!related_built_products) {
            return null;
        }

        const groupDiscount = Object.values(attributes).filter(
            (element) => element.attribute_code === 'built_discount'
        );

        const discount = groupDiscount ? (100 - groupDiscount[0].attribute_value) / 100 : 1;

        const finalPrice = this.builtProductsPrice(related_built_products, 'final_price') * discount;

        this.setState({ finalPrice });

        return (
            <>
                <div>{__('Price for set')}:</div>
                <div block="ProductActions" elem="PriceWrapper" mods={{ type: 'setPrice' }}>
                    <span>{formatPrice(finalPrice, this.getPriceCurrency())}</span>
                </div>
            </>
        );
    }

    renderArrangementPrice() {
        const {
            productPrice,
            product: {
                attributes: {
                    built_discount: { attribute_value },
                },
            },
        } = this.props;
        const arrangementDiscountAvailable = !!attribute_value;

        if (!productPrice) {
            return null;
        }
        // TODO if fnregular returns value !== fnfinal returns value render price with discount
        if (arrangementDiscountAvailable) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div block="ProductPrice" mix={{ block: 'ProductCard', elem: 'Price' }}>
                        <ins block="ProductPrice" elem="Price">
                            <span block="ProductPrice" elem="PriceValue">
                                {this.renderFinalPrice()}
                            </span>
                        </ins>
                        <del block="ProductPrice" elem="HighPrice">
                            {this.renderRegularPrice()}
                        </del>
                    </div>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <div block="ProductPrice" mix={{ block: 'ProductCard', elem: 'Price' }}>
                    <span block="ProductPrice" elem="Price">
                        <span block="ProductPrice" elem="PriceValue">
                            {this.renderFinalPrice()}
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const {
            linkTo,
            product: { url, alternative_hover_image },
        } = this.props;
        const { path: alternativeHoverImagePath } = alternative_hover_image || {};

        const altImage = alternativeHoverImagePath !== NO_SELECTION;
        if (!url) {
            return (
                <div block="ProductCard" elem="Link" mods={{ altImage }}>
                    {children}
                </div>
            );
        }

        return (
            <Link
                block="ProductCard"
                elem="Link"
                mods={{ altImage }}
                to={linkTo}
                onClick={this.registerSharedElement}
                mix={mix}
            >
                {children}
            </Link>
        );
    }

    render() {
        const { children, mix, isLoading, layout, isNotListElement } = this.props;

        if (isNotListElement) {
            return (
                <div block="ProductCard" mods={{ layout, isNotListElement }} mix={mix}>
                    <Loader isLoading={isLoading} />
                    {this.renderCardContent()}
                    {this.renderVariants()}
                    <div block="ProductCard" elem="AdditionalContent">
                        {children}
                    </div>
                </div>
            );
        }

        if (layout === LIST_LAYOUT) {
            return (
                <li block="ProductCard" mods={{ layout }} mix={mix}>
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <li block="ProductCard" mods={{ layout }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderLabel()}
                {this.renderCardContent()}
                {this.renderVariants()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default ProductCard;
