import { Field } from 'Util/Query';

const addBaseFields = (args, callback, instance) => {
    const {
        options: { isArrangementCategory = false, isArrangementWidget = false },
    } = instance;

    if (isArrangementCategory || isArrangementWidget) {
        return Array.from(callback(...args));
    }

    return [
        ...callback(...args),
        new Field('crosssell_products').addFieldList([
            'id',
            'sku',
            'name',
            'url',
            'product_unit',
            'vp_items',
            instance._getProductImageField(),
            instance._getProductThumbnailField(),
            instance._getPriceRangeField(),
            instance._getLabelsField(),
        ]),
    ];
};

export default {
    'Pwa/Query/ProductList/Query/ProductListQuery': {
        'member-function': {
            _getProductInterfaceFields: addBaseFields,
        },
    },
};
