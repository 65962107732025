import React, { lazy } from 'react';
import LazyLoad from 'react-lazyload';

import TextPlaceholder from 'Component/TextPlaceholder';
import {
    HomeSlider,
    NewProducts,
    ProductListWidget,
    RecentlyViewedWidget,
    WidgetFactory as SourceWidgetFactory,
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    BUILD_PRODUCT_WITH_HOTSPOT,
    BUILD_PRODUCT_WITHOUT_HOTSPOT,
    CATALOG_PRODUCT_LIST,
    CATEGORIES,
    EXTENDED,
    MAGEFAN_FEATURED,
    MAGEFAN_RECENT,
    NEW_PRODUCTS,
    RECENTLY_VIEWED,
    SLIDER,
    STANDARD,
    STANDARD_WITHOUT_MOBILE,
} from './WidgetFactory.config';

export const BlogWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-blog" */
        'Component/BlogWidget'
    )
);

export const ProductListWidgetStd = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-product-list" */
        'Component/ProductListWidget'
    )
);
export const ProductListWidgetExtended = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-product-list-extended" */
        'Component/ProductListWidgetExtended'
    )
);
export const ProductListWidgetStdWithoutMobile = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-product-list-std" */
        'Component/ProductListWidgetWithoutMobile'
    )
);
export const CategoriesWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-categories" */
        'Component/CategoriesWidget'
    )
);
export const ProductWithHotspotsWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-product-with-hotspots" */
        'Component/ProductWithHotspotsWidget'
    )
);
export const ProductListWithoutHotspotsWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-product-list-without-hotspots" */
        'Component/ProductListWithoutHotspotsWidget'
    )
);

export { NewProducts, HomeSlider, RecentlyViewedWidget };

/** @namespace Pwa/Component/WidgetFactory/Component/WidgetFactory */
export class WidgetFactory extends SourceWidgetFactory {
    renderMap = {
        [SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback,
        },
        [NEW_PRODUCTS]: {
            component: NewProducts,
        },
        [CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget,
            fallback: () => (
                <div block="ProductList" mix={{ block: 'ProductListWidget' }}>
                    <div block="ProductListWidget" mods={{ placeholder: true }}>
                        <TextPlaceholder length="full" />
                    </div>
                </div>
            ),
        },
        [CATEGORIES]: {
            component: CategoriesWidget,
        },
        [STANDARD]: {
            component: ProductListWidgetStd,
            fallback: () => (
                <div block="ProductList ProductListWidget">
                    <div block="ProductListWidget" mods={{ placeholder: true }}>
                        <TextPlaceholder length="full" />
                    </div>
                </div>
            ),
        },
        [EXTENDED]: {
            component: ProductListWidgetExtended,
            fallback: () => (
                <div block="ProductList" mix={{ block: 'ProductListWidget', mods: { extended: true } }}>
                    <div block="ProductListWidget_extended_placeholder" mods={{ extended: 'placeholder' }}>
                        <TextPlaceholder length="full" />
                    </div>
                </div>
            ),
        },
        [STANDARD_WITHOUT_MOBILE]: {
            component: ProductListWidgetStdWithoutMobile,
        },
        [MAGEFAN_FEATURED]: {
            component: BlogWidget,
        },
        [MAGEFAN_RECENT]: {
            component: BlogWidget,
        },
        [RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget,
        },
        [BUILD_PRODUCT_WITH_HOTSPOT]: {
            component: ProductWithHotspotsWidget,
            fallback: () => (
                <div block="ProductWithHotspotsWidget" mods={{ placeholder: true }}>
                    <TextPlaceholder length="full" />
                </div>
            ),
        },
        [BUILD_PRODUCT_WITHOUT_HOTSPOT]: {
            component: ProductListWithoutHotspotsWidget,
            fallback: () => (
                <div block="ProductListWithoutHotspotsWidget" mods={{ placeholder: true }}>
                    <TextPlaceholder length="full" />
                </div>
            ),
        },
    };

    renderSliderFallback() {
        return (
            <div block="Slider">
                <TextPlaceholder length="full" />
            </div>
        );
    }

    renderContent() {
        const {
            type,
            sliderId = null,
            displayType,
            productsCount,
            showPager,
            sortOrder,
            storeId,
            tagId,
            title,
            authorId,
            categoryId,
            conditionsEncoded,
            postsIds,
            productsListType,
            numberOfPosts,
        } = this.props;

        const { component: Widget, fallback = this.renderDefaultFallback } = productsListType
            ? this.renderMap[productsListType] || {}
            : this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <LazyLoad once offset={100} placeholder={fallback()}>
                    <Widget
                        sliderId={sliderId}
                        displayType={displayType}
                        authorId={authorId}
                        categoryId={categoryId}
                        numberOfPosts={numberOfPosts}
                        postsIds={postsIds}
                        productsCount={productsCount}
                        sortOrder={sortOrder}
                        showPager={showPager}
                        storeId={storeId}
                        tagId={tagId}
                        title={title}
                        type={type}
                        conditionsEncoded={conditionsEncoded}
                    />
                </LazyLoad>
            );
        }

        return null;
    }

    renderFallback() {
        const { type } = this.props;
        const { fallback = this.renderDefaultFallback } = this.renderMap[type] || {};

        return fallback();
    }
}

export default WidgetFactory;
