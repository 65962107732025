import SlickSlider from 'react-slick';

import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Popup from 'Component/Popup';
import ProductLabel from 'Component/ProductLabel';
import SliderProgressBar from 'Component/SliderProgressBar';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';

import CrossSellProductCard from '../CrossSellProductCard';
import { PRODUCT_CROSS_SELL_POPUP_ID } from './CrossSellPopup.config';
import {
    CROSS_SELL_MAX_SLIDES,
    CROSS_SELL_SLIDER_OPTIONS,
    CROSS_SELL_SLIDES_TO_SHOW_DESKTOP,
    CROSS_SELL_SLIDES_TO_SHOW_MOBILE,
    CROSS_SELL_SLIDES_TO_SHOW_TABLET,
} from './CrossSellPopupSlider.config';

import './CrossSellPopup.style';
import 'swiper/swiper.scss';

/** @namespace Domneo/Crosssellpopup/Component/CrossSellPopup/Component/CrossSellPopupComponent */

export class CrossSellPopupComponent extends SourceProductCard {
    state = {
        currentSlide: 1,
        isSlider: false,
    };

    __construct(props) {
        super.__construct(props);
        this.props = props;
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    componentDidMount() {
        const {
            product: { crosssell_products },
            device: { isMobile, isTablet },
        } = this.props;

        const isDesktop = !isMobile && !isTablet;
        const numberOfItems = crosssell_products?.length;
        const showSlider =
            (isMobile && numberOfItems > CROSS_SELL_SLIDES_TO_SHOW_MOBILE) ||
            (isTablet && numberOfItems > CROSS_SELL_SLIDES_TO_SHOW_TABLET) ||
            (isDesktop && numberOfItems > CROSS_SELL_SLIDES_TO_SHOW_DESKTOP);

        if (showSlider) {
            this.setState({ isSlider: true });
        }
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    renderCrossSellItem = () => {
        const {
            product: { name },
            thumbnail,
        } = this.props;

        return (
            <div block="CrossSellPopup" elem="Item">
                {this.renderLabel()}
                <Image block="CrossSellPopup" elem="Image" src={thumbnail || ''} />
                <div block="CrossSellPopup" elem="ItemData">
                    <div block="CrossSellPopup" elem="ItemName">
                        {name}
                    </div>
                    <div block="CrossSellPopup" elem="Price">
                        {this.renderPrice()}
                    </div>
                </div>
            </div>
        );
    };

    renderCrossSellHeader() {
        return (
            <div block="CrossSellPopup" elem="Header">
                <p>{__('Pick accessories')}:</p>
            </div>
        );
    }

    renderSliderButtons() {
        const {
            device: { isMobile },
        } = this.props;

        return (
            <>
                <button block="CrossSellPopup" elem="SliderPrevButton" onClick={this.previousSlide}>
                    <Icons name="arrow_left" fill={isMobile && '#10069F'} />
                </button>
                <button block="CrossSellPopup" elem="SliderNextButton" onClick={this.nextSlide}>
                    <Icons name="arrow_right" fill={isMobile && '#10069F'} />
                </button>
            </>
        );
    }

    beforeChange = (_, nextSlide) =>
        this.setState({
            currentSlide: nextSlide + 1,
        });

    refFunc = (slider) => (this.slider = slider);

    renderCrossSellProducts() {
        const {
            product: { crosssell_products },
        } = this.props;
        const { isSlider } = this.state;

        if (!crosssell_products) {
            return null;
        }
        if (isSlider)
            return (
                <div block="CrossSellPopup" elem="Slider">
                    <SlickSlider
                        beforeChange={this.beforeChange.bind(this)}
                        ref={this.refFunc.bind(this)}
                        {...CROSS_SELL_SLIDER_OPTIONS}
                    >
                        {Array.from(crosssell_products).slice(0, CROSS_SELL_MAX_SLIDES).map(this.renderProductCard)}
                    </SlickSlider>
                    {this.renderSliderButtons()}
                    {this.renderProgressBar()}
                </div>
            );
        return (
            <div block="CrossSellPopup" elem="Products">
                {Array.from(crosssell_products).slice(0, CROSS_SELL_MAX_SLIDES).map(this.renderProductCard)}
            </div>
        );
    }

    getNumberOfSlides = () => {
        const {
            product: { crosssell_products },
        } = this.props;

        const items = crosssell_products?.length;

        return items;
    };

    renderProgressBar() {
        const { currentSlide } = this.state;
        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={this.getNumberOfSlides()} />;
    }

    renderLabel() {
        const {
            product: { labels },
        } = this.props;

        return <ProductLabel labels={labels} />;
    }

    renderProductCard = (product) => (
        <div block="CrossSellPopup" elem="ProductCardWrapper" key={product.id}>
            <CrossSellProductCard product={product} />
        </div>
    );

    renderContinueShopping() {
        const { hidePopup } = this.props;

        return (
            <button block="CrossSellPopup" elem="ContinueButton" mix={{ block: 'Button' }} onClick={hidePopup}>
                {__('Continue shopping')}
            </button>
        );
    }

    renderGoToCart() {
        const { onGoToCart } = this.props;

        return (
            <button block="CrossSellPopup" elem="CartButton" mix={{ block: 'Button' }} onClick={onGoToCart}>
                {__('See cart')}
            </button>
        );
    }

    renderNavigationButtons = () => (
        <>
            {this.renderContinueShopping()}
            {this.renderGoToCart()}
        </>
    );

    renderPopupFooter() {
        return (
            <div block="CrossSellPopup" elem="Footer">
                <div block="CrossSellPopup" elem="Buttons">
                    {this.renderNavigationButtons()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="CrossSellPopup">
                <Popup id={PRODUCT_CROSS_SELL_POPUP_ID} mix={{ block: 'CrossSellPopup' }}>
                    {this.renderCrossSellItem()}
                    {this.renderCrossSellHeader()}
                    {this.renderCrossSellProducts()}
                    {this.renderPopupFooter()}
                </Popup>
            </div>
        );
    }
}

export default CrossSellPopupComponent;
