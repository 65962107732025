import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer,
} from 'Component/ProductCard/ProductCard.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { history as browserHistory } from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import CrossSellPopup from './CrossSellPopup.component';

/** @namespace Crosssellpopup/Component/CrossSellPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    ...sourceMapStateToProps(_state),
});

/** @namespace Crosssellpopup/Component/CrossSellPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    ...sourceMapDispatchToProps(_dispatch),
    hidePopup: () => _dispatch(showPopup('', {})),
});

/** @namespace Crosssellpopup/Component/CrossSellPopup/Container/CrossSellPopupContainer */
export class CrossSellPopupContainer extends ProductCardContainer {
    static propTypes = {
        ...ProductCardContainer.propTypes,
    };

    containerFunctions = {
        ...this.containerFunctions,
        onGoToCart: this.onGoToCart.bind(this),
    };

    containerProps() {
        return {
            ...super.containerProps(),
            ...this.props,
        };
    }

    onGoToCart() {
        const { hidePopup } = this.props;
        hidePopup();
        browserHistory.push(appendWithStoreCode('/cart'));
    }

    render() {
        return <CrossSellPopup {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrossSellPopupContainer);
